import facebookImg from '../../img/facebook.png';
import instagramImg from '../../img/instagram.png';
import linkedinImg from '../../img/linkedin.png';
import youtubeImg from '../../img/youtube.png';
import greatPlaceImg from '../../img/great-place-to-work.svg';
import logoTransparentImg from '../../img/lansing-logo-transparent.png';
import WarehouseProps from '../../interfaces/common/WarehouseProps';

const Footer:React.FC<WarehouseProps> = ({ warehouseCode }) => {
    return (
        <div className="footer">     
            <div className="footer-main">
                <div className="connect">
                    <p>Connect with us</p>
                    <ul className="socialNetworks">
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/lansing_bp/">
                                <img src={instagramImg} width={30} alt="Instagram Logo" />                            
                            </a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/lansingbuildingproducts">
                                <img src={facebookImg} width={30} alt="Facebook Logo" />                            
                            </a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/lansing-building-products/">
                                <img src={linkedinImg} width={30} alt="LinkedIn Logo" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC_5LR4NyFQ59X1CqyNEKN_Q">
                                <img src={youtubeImg} width={30} alt="Youtube Logo" />
                            </a>
                        </li>
                    </ul>
                    <p>Copyright © {new Date().getFullYear()} LansingNOW. All rights reserved. | <a href={process.env.REACT_APP_WEB_URL + "/privacy"}>Privacy Policy</a> | <a href={process.env.REACT_APP_WEB_URL + "/common/contactus?warehouseCode=" + warehouseCode}>Contact us</a></p>
                </div>
                    <img src={greatPlaceImg} height="60" width="60" alt="Great Place To Work Logo" />
            </div>
            <div className="footer-newsletter">
                <img src={logoTransparentImg}  alt="Lansing Logo" />
            </div>
        </div>
    );
}

export default Footer;