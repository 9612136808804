// import WelcomeBanner from './welcomeBanner';
import HomePageCategories from './homepageCategories';
import HomepageProductTemplate from './homepageProductTemplate';
import HomepageCustomerPrograms from './homepageCustomerPrograms';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import QuickLinks from './quickLinks';
import HomepageFeatureZone from './homepageFeatureZone';
import HomePageProps from '../../interfaces/homepage/HomePageProps';
import PageTitle from '../../utils/pageTitle';

const Homepage: React.FC<HomePageProps> = ({ warehouseName, warehouseCode, warehouseFullAddress, warehouseAddress, 
  warehouseCity, warehouseState, zipCode, isNEDivision, warehouseHoursFrom, warehouseHoursTo, 
  message, allWarehouses, onUseCurrentLocation }) => {

  PageTitle(null);   // Set page title
  
  return (
    <div className='page-margin'>
      <HomepageFeatureZone 
          warehouseName={warehouseName} 
          warehouseCode={warehouseCode} 
          warehouseFullAddress={warehouseFullAddress} 
          warehouseAddress={warehouseAddress}
          warehouseCity={warehouseCity}
          warehouseState={warehouseState}
          zipCode={zipCode}
          warehouseHoursFrom={warehouseHoursFrom} 
          isNEDivision={isNEDivision}
          warehouseHoursTo={warehouseHoursTo} 
          message={message}
          allWarehouses={allWarehouses}
          onUseCurrentLocation={onUseCurrentLocation} />
    
      {/* <WelcomeBanner /> */}

      <Container maxWidth="lg">
        <h1>Featured Categories</h1>
        <Grid container spacing={1}>
          <HomePageCategories />
        </Grid>
      </Container>
      <HomepageProductTemplate />
      
      <HomepageCustomerPrograms warehouseCode={warehouseCode} isNEDivision={isNEDivision} />
      

      <Container maxWidth="lg">
        <QuickLinks warehouseCode={warehouseCode} warehouseName={warehouseName} />
      </Container>
    </div>
  );
}

export default Homepage;
