const PageTitle = (pageName: string | null) => {
    var title = `Lansing Building Products${pageName === null ? "" : ". " + pageName}`;
    if (process.env.REACT_APP_ENV === "staging") 
        title = `UAT - ${title}`;
    else if (process.env.REACT_APP_ENV === "test") 
        title = `TEST - ${title}`;
    else if (process.env.REACT_APP_ENV === "development") 
        title = `LOCAL - ${title}`;

    document.title = title;
}

export default PageTitle;