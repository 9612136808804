import React, { useEffect } from 'react';

const FaviconSetter: React.FC = () => {
    useEffect(() => {
        let env = process.env.REACT_APP_ENV;

        let faviconPath = '/favicon.ico'; // Default favicon

        if (env === 'test')
            faviconPath = `/${process.env.REACT_APP_SUBDIRECTORY}/favicon-red.ico`;
        else if (env === 'production')
            faviconPath = `/${process.env.REACT_APP_SUBDIRECTORY}/favicon.ico`;
        else 
            faviconPath = `/${process.env.REACT_APP_SUBDIRECTORY}/favicon-orange.ico`;

        const link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
        if (link) {
            link.href = faviconPath;
        }
    }, []);

    return null;
};

export default FaviconSetter;
