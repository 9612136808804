import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import PageTitle from "../../utils/pageTitle";
import marketingServicesBannerImg from '../../img/program-marketingservices-banner.png';
import marketingServicesLogoImg from '../../img/program-marketingservices-logo.png';
import Stack from '@mui/material/Stack';
import SeoIgnore from "../../utils/seoIgnore";


const MarketingServices = () => {
    PageTitle("Lansing Marketing Services");   // Set page title

    let brochureUrl: string = process.env.REACT_APP_WEB_URL + "/images/uploaded/Lansing Marketing Services brochure 2024.pdf";
    let quickGuideUrl: string = process.env.REACT_APP_WEB_URL + "/images/uploaded/Lansing Marketing Services Quick Reference Guide_4.23.pdf";
    
    return (
        <Grid>
            <SeoIgnore></SeoIgnore>
            <div className='cp_backgroundImage' style={{backgroundImage: `url(${marketingServicesBannerImg})`}}>
                <Container maxWidth="lg">
                    <h1>
                        Lansing Marketing Services
                    </h1>
                </Container>
            </div>
 

            <Container maxWidth="lg" sx={{my:5}}>
                <Grid container spacing={0} direction="column">
                    <img src={marketingServicesLogoImg} width="450" height="auto" alt='Lansing Marketing Services' />

                    <p className='customerProgramsFont'>Lansing Marketing Services is dedicated to help support and take on the marketing initiatives we know are critical to your business's success, but you just don't have time for. With a dedicated in-house marketing team that specializes in your industry, we're here to help your company get noticed and, most importantly, help you generate more leads. The marketing services are provided at no cost for our valued customers. And you benefit from the discounted rates that we can provide on any items you have printed or produced. Working together, we want to help you increase profits and ultimately grow your business.</p>
                    <h4>Some of the custom designs we can provide:</h4>
                    <ul className='customerProgramsFont'>
                        <li>Logo Design and Branding</li>
                        <li>Signage: banners, job signs, posters, trade show materials, truck signs</li>
                        <li>Marketing Materials: postcards, flyers, door hangers, brochures</li>
                        <li>Business Materials: coupons, contracts, forms, business cards, pocket folders</li>
                        <li>Digital Presentations, Digital Guides, Digital Consultation</li>
                        <li>Promotional Items &amp; Wearables like t-shirts, ball caps, pens, and more</li>
                    </ul>
                    <Stack direction="row" spacing={2} sx={{my:4}}>
                        <a className='blueButtonOutline' href={brochureUrl} target="_blank" rel="noreferrer">BROCHURE</a>
                        <a className='blueButtonOutline' href={quickGuideUrl} target="_blank" rel="noreferrer">QUICK REFERENCE GUIDE</a>
                    </Stack>

                    <p className='customerProgramsFont'>Need help with your next marketing project? Contact <a href="mailto:customer.marketing@lansingbp.com">Lansing Marketing Services</a> today.</p>
                </Grid>
            </Container>
        </Grid>
    );
}

export default MarketingServices;