import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import PageTitle from "../../utils/pageTitle";
import savrBannerImg from '../../img/program-savr-banner.png';
import Stack from '@mui/material/Stack';
import SeoIgnore from "../../utils/seoIgnore";

const Savr = () => {
    PageTitle("SAVR");   // Set page title

    let learnMoreUrl: string = process.env.REACT_APP_WEB_URL + `/quicklogin?returnUrl=${encodeURIComponent("/programs/savr")}`;
    
    return (
        <Grid>
            <SeoIgnore></SeoIgnore>
            <div className='cp_backgroundImage' style={{backgroundImage: `url(${savrBannerImg})`}}>
                <Container maxWidth="lg">
                    <h1>SAVR: Support for Automated Vendor Rewards</h1>
                </Container>
            </div>
            <Container maxWidth="lg" sx={{my:5}}>
                <Grid container spacing={0} direction="column">
                    <h4 style={{lineHeight:"30px"}}>
                        Connect your manufacturer rewards programs to Lansing SAVR and we'll <span style={{textDecoration: "underline"}}>automatically track and submit</span> your qualifying purchases each month. Let us do the work so you can enjoy your hard-earned rewards!
                    </h4>
                    <h3>
                        HOW IT WORKS:
                    </h3>
                    <ul className='customerProgramsFont'> 
                        <li>At the beginning of each month, Lansing compiles your qualifying purchases from the previous month for the rewards programs you connect to SAVR.</li>
                        <li>Lansing submits your qualifying purchases directly to each manufacturer's rewards program for processing and sends you a confirmation email.</li>
                        <li>Once the manufacturer's program has processed your qualifying purchases, they will post your rewards on your account for that program. <em>Note: It often takes each manufacturer program several weeks to process your earned rewards.</em></li>
                    </ul>
                    <Stack direction="row" spacing={2} sx={{my:4}}>
                        <a className='blueButtonOutline' href={learnMoreUrl} rel="noreferrer">LEARN MORE</a>
                    </Stack>                    
                    
                </Grid>
            </Container>
        </Grid>
    );
}

export default Savr;