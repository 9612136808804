import { Helmet } from "react-helmet";

const SeoIgnore = () => {
    return (
        <Helmet>
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>
    );
}

export default SeoIgnore;