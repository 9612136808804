import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import PageTitle from "../../utils/pageTitle";
import proBucksBannerImg from '../../img/program-probucks-banner.jpeg';
import Stack from '@mui/material/Stack';
import SeoIgnore from "../../utils/seoIgnore";


const ProBucks = () => {
    PageTitle("ProBucks");   // Set page title

    let learnMoreUrl: string = process.env.REACT_APP_WEB_URL + `/quicklogin?returnUrl=${encodeURIComponent("/programs/probucks")}`;
    let clickHereUrl: string = process.env.REACT_APP_WEB_URL + "/images/uploaded/Lansing ProBucks Customer Brochure.pdf";
    let faqUrl: string = process.env.REACT_APP_WEB_URL + "/images/uploaded/Lansing ProBucks Customer FAQ.pdf";
    
    return (
        <Grid>
            <SeoIgnore></SeoIgnore>
            <div className='cp_backgroundImage'  style={{backgroundImage: `url(${proBucksBannerImg})`}}>
                <Container maxWidth="lg">
                    <h1>ProBucks</h1>
                </Container>                
            </div>
            <Container maxWidth="lg" sx={{my:5}}>
                <Grid container spacing={0} direction="column">
                    <h3>Buy products, earn ProBucks...it's that easy!</h3>

                    <p className='customerProgramsFont'>Lansing ProBucks is simple: Easily enroll online, or ask to sign up at your local branch. Once enrolled, we'll track your purchases, and for every $1,000* you spend, you'll automatically earn ProBucks to use towards future purchases at Lansing Building Products.</p>
                    
                    <p className='customerProgramsFont'>The more you buy, the more you'll save. As your purchase levels increase, watch your Lansing ProBucks stack up!</p>

                    <table className='customerProgramsTable'>
                        <tbody>
                        <tr>
                            <th>Purchases</th>
                            <th>ProBucks Earned</th>
                        </tr>
                        <tr>
                            <td>$1,000 - $5,000</td>
                            <td>$50 per $1,000</td>
                        </tr>
                        <tr>
                            <td>$6,000 - $10,000</td>
                            <td>$75 per $1,000</td>
                        </tr>
                        <tr>
                            <td>$11,000 - $50,000</td>
                            <td>$100 per $1,000</td>
                        </tr>
                        </tbody>
                    </table>
                    
                    <Stack direction="row" spacing={2} sx={{my:4}}>
                        <a className='blueButtonOutline' href={learnMoreUrl} rel="noreferrer">LEARN MORE</a>
                    </Stack>  

                    

                    <h3>Additional Resources</h3>

                    <p className='customerProgramsFont'>For a program brochure outlining ProBucks, <a href={clickHereUrl} target="_blank" rel="noreferrer">click here</a>.</p>
                
                    <p className='customerProgramsFont' style={{fontSize: "80%"}}>
                        <i>
                            *A customer account must meet certain eligibility requirements to be enrolled in ProBucks. Once enrolled, an account's qualifying sales from the enrollment date forward will be calculated automatically. Sales will not be retroactive or credited for any reward. The account must remain in good credit standing to qualify. Once ProBucks rewards are earned, they can only be used towards a future purchase and will expire 3 months from issue date. Lansing Building Products reserves the right to change program rules and/or to remove a customer at any time. For eligibility requirements and exclusions, please see our <a href={faqUrl} target='_blank' rel="noreferrer">FAQ document</a>.
                        </i>
                    </p>
                </Grid>
            </Container>
        </Grid>
    );
}

export default ProBucks;