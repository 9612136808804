import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import PageTitle from "../../utils/pageTitle";
import impactRewardsBannerImg from '../../img/program-impactrewards-banner.jpeg';
import Stack from '@mui/material/Stack';
import SeoIgnore from "../../utils/seoIgnore";


const ImpactRewards = () => {
    PageTitle("IMPACT Rewards");   // Set page title

    let learnMoreUrl: string = process.env.REACT_APP_WEB_URL + `/quicklogin?returnUrl=${encodeURIComponent("/programs/impactrewards")}`;
    let clickHereUrl: string = process.env.REACT_APP_WEB_URL + "/images/uploaded/Lansing Impact Rewards Customer Brochure.pdf";
    
    return (
        <Grid>
            <SeoIgnore></SeoIgnore>
            <div className='cp_backgroundImage'  style={{backgroundImage: `url(${impactRewardsBannerImg})`}}>
                <Container maxWidth="lg">
                    <h1>IMPACT Rewards</h1>
                </Container>                   
            </div>
            <Container maxWidth="lg" sx={{my:5}}>
                <Grid container spacing={0} direction="column">
                    <h3>Buy products, earn IMPACT Rewards...it's that easy!</h3>

                    <p className='customerProgramsFont'>Lansing IMPACT Rewards is simple: Easily enroll online, or ask to sign up at your local branch. Once enrolled, we'll track your purchases, and for every $1,000* you spend, you'll automatically earn IMPACT Rewards to use towards future purchases at Lansing Building Products.</p>
                    
                    <p className='customerProgramsFont'>The more you buy, the more you'll save. As your purchase levels increase, watch your Lansing IMPACT Rewards stack up!</p>

                    <table className='customerProgramsTable'>
                        <tbody>
                        <tr>
                            <th>Purchases</th>
                            <th>IMPACT Rewards Earned</th>
                        </tr>
                        <tr>
                            <td>$1,000 - $5,000</td>
                            <td>$50 per $1,000</td>
                        </tr>
                        <tr>
                            <td>$6,000 - $10,000</td>
                            <td>$75 per $1,000</td>
                        </tr>
                        <tr>
                            <td>$11,000 - $50,000</td>
                            <td>$100 per $1,000</td>
                        </tr>
                        </tbody>
                    </table>
                    
                    <Stack direction="row" spacing={2} sx={{my:4}}>
                        <a className='blueButtonOutline' href={learnMoreUrl} rel="noreferrer">LEARN MORE</a>
                    </Stack>                      

                    <h3>Additional Resources</h3>

                    <p className='customerProgramsFont'>For a program brochure outlining IMPACT Rewards, <a href={clickHereUrl} target="_blank" rel="noreferrer">click here</a>.</p>
                
                    <p className='customerProgramsFont' style={{fontSize: "80%"}}>
                        <i>
                            * Upon enrollment, an account's qualifying sales from the enrollment date forward will be calculated automatically. Sales will not be retroactive or credited for any reward. Account must remain in good standing to qualify. Once a reward is earned, it will expire 6 months from issue date. Rewards are earned on purchases up to $50,000. For eligibility requirements and exclusions, please see a branch or sales representative for further details.
                        </i>
                    </p>

                    
                </Grid>
            </Container>
        </Grid>
    );
}

export default ImpactRewards;