
import algoliasearch from 'algoliasearch/lite';
import { ClearRefinements, Configure, CurrentRefinements, useHits, usePagination , UseHitsProps, HitsPerPage, InstantSearch, useStats, useRefinementList, RefinementList, ToggleRefinement, CurrentRefinementsProps, RefinementListProps, SearchBox, Highlight, PaginationProps } from 'react-instantsearch';
import GalleryHitsProps from '../../interfaces/productGallery/GalleryHitsProps'
import Dictionary from '../../interfaces/common/Dictionary'
import GalleryCustomStatsProps from '../../interfaces/productGallery/GalleryCustomStatsProps'
import WarehouseProps from '../../interfaces/common/WarehouseProps';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography, Collapse, Skeleton, Grid, Stack } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import CustomRefinementListProps from '../../interfaces/productGallery/CustomRefinementListProps';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import ProductGalleryImage from './productGalleryImage';
import PageTitle from '../../utils/pageTitle';
import defaultProductImg from '../../img/default-image_420.png';
import featuredImg from '../../img/featured-flag.png';
import VersionUrl from "../../utils/versionUrl"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SeoIgnore from "../../utils/seoIgnore";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ProductGallery: React.FC<WarehouseProps> = ({ warehouseCode }) => {
    
    PageTitle(null);   // Set page title
    
    const navigate = useNavigate();

    const RedirectToProduct = (productUrl: string) => navigate(productUrl);
    
    const CustomHits = (props: UseHitsProps) => {        
        const { items } = useHits(props);

        return (
            <div className='ais-Hits'>
                <ol className='ais-Hits-list'>
                    {items.map((hit: any, index: number) => (
                        <li key={hit.sku} className='ais-Hits-item'>
                            <ProductGalleryHit hit={hit} position={index}></ProductGalleryHit>
                        </li>
                    ))}
                </ol>
            </div>
        );
      }

    const ProductGalleryHit = ({ hit, position }: GalleryHitsProps) => {
        const [loaded, setLoaded] = useState<boolean>(false);
        const [customWindows, setCustomWindows] = useState<boolean>(false);
        const [type, setType] = useState<string | null>(null);
        const [inventoryStatus, setInventoryStatus] = useState<string | null>(null);
        const [stockedColorRgb, setStockedColorRgb] = useState<string | null>(null);
        const [stockedColor, setStockedColor] = useState<string | null>(null);
        const [stockedMeasure, setStockedMeasure] = useState<string | null>(null);
        const [stockedVariantsCount, setStockedVariantsCount] = useState<number>(0);
        const [specialColorRgb, setSpecialColorRgb] = useState<string | null>(null);
        const [specialColor, setSpecialColor] = useState<string | null>(null);
        const [specialMeasure, setSpecialMeasure] = useState<string | null>(null);
        const [specialVariantsCount, setSpecialVariantsCount] = useState<number>(0);
        const [mainPictureOverlay, setMainPictureOverlay] = useState<string | null>(null);
        const [customWindowPrice, setCustomWindowPrice] = useState<string | null>(null);

        useEffect(() => {
            if (warehouseCode !== null && !loaded) {
                setLoaded(true);

                const timer = setTimeout(() => {
                    axios.get(VersionUrl(process.env.REACT_APP_API_URL + "/GetProductDetails"), {
                        params: {
                            id: hit.id,
                            warehouseCode: warehouseCode
                        }
                    })
                    .then(response => {
                        setType(response.data.productDetails.type);
                        setCustomWindows(response.data.productDetails.customWindows);
                        setInventoryStatus(response.data.productDetails.inventoryStatus);
                        setStockedColorRgb(response.data.productDetails.stockedColorRgb);
                        setStockedColor(response.data.productDetails.stockedColor);
                        setStockedMeasure(response.data.productDetails.stockedMeasure);
                        setStockedVariantsCount(response.data.productDetails.stockedVariantsCount);
                        setSpecialColorRgb(response.data.productDetails.specialColorRgb);
                        setSpecialColor(response.data.productDetails.specialColor);
                        setSpecialMeasure(response.data.productDetails.specialMeasure);
                        setSpecialVariantsCount(response.data.productDetails.specialVariantsCount);
                        setCustomWindowPrice(response.data.productDetails.customWindowsPrice);

                        var variantDetails = response.data.productDetails.variantDetails;                        
                        if (variantDetails !== null && variantDetails.overlayRGB !== null)
                            setMainPictureOverlay(variantDetails.overlayRGB);
                        else if (response.data.productDetails.overlayRGB != null)
                            setMainPictureOverlay(response.data.productDetails.overlayRGB);

                        setLoading(false);
                    })
                    .catch(error => {
                        console.error(error);
                        setLoading(false);
                    });
                }, position * 200);
                return () => clearTimeout(timer);
            }
        }, []);

        const [loading, setLoading] = useState(true);

        useEffect(() => {
            const timer = setTimeout(() => {
                setLoading(false);
            }, 1000);
            return () => clearTimeout(timer);
        }, []);

        var imageUrl = defaultProductImg;
        if (hit.pictureperwarehouse !== null && hit.pictureperwarehouse.length > 0) {
            var warehouseImage = hit.pictureperwarehouse.find(x => x.item1 !== null && x.item1.toLowerCase() === (warehouseCode ?? "").toLowerCase())
            if (warehouseImage !== null && warehouseImage !== undefined)
                imageUrl = `${process.env.REACT_APP_CDN_URL}/${warehouseImage.item2}`;
            else {
                warehouseImage = hit.pictureperwarehouse.find(x => x.item1 === "");
                if (warehouseImage !== null && warehouseImage !== undefined)
                    imageUrl = `${process.env.REACT_APP_CDN_URL}/${warehouseImage.item2}`;
            }
        }
        
        let productUrl = `/product?id=${hit.id}`;
        
        return (        
            <Grid item md={12} sx={{px:1}}>
                <SeoIgnore></SeoIgnore>
                <Box className={"productCard"}>
                    {loading ? (
                        <Skeleton variant="rectangular"animation="wave" width={"100%"} height={280} />
                    ) : (
                        <div className='image-wrapper handCursor' onClick={() => RedirectToProduct(productUrl)}>
                            <ProductGalleryImage
                                src={imageUrl}
                                fallback={defaultProductImg}
                                alt="Product Gallery"
                                mainPictureOverlay={mainPictureOverlay} />
                        </div>
                    )}
                    <Box sx={{ mt: 2, minHeight:"280px" }}>
                        {loading ? (
                        <div className='bottomCardHolder'>
                            <div>
                                <Skeleton animation="wave" variant="text" width="80%" />
                                <Skeleton animation="wave" variant="text" width="80%" />
                                <Skeleton animation="wave" variant="text" width="60%" />
                                <Skeleton animation="wave" variant="text" width="60%" />
                            </div>
                            <Skeleton animation="wave" variant="rectangular" width={"100%"} height={50}/>
                        </div>
                        ) : (
                        <div className='bottomCardHolder'>
                            <div className='bottomCardHolderText'>
                                <div>
                                    <Typography className='bottomCardHolderName handCursor' variant="h6" sx={{fontSize:"16px", fontWeight: 700}} onClick={() => RedirectToProduct(productUrl)}>
                                        <Highlight attribute="name" hit={hit} />
                                    </Typography>
                                    {hit.manufacturername === null || hit.manufacturername === undefined ? <></> : (
                                        <Typography className='bottomCardHolderBrand handCursor' variant="body2" onClick={() => RedirectToProduct(productUrl)}>
                                            <b>Brand:</b> <Highlight attribute="manufacturername" hit={hit} />
                                        </Typography>
                                    )}
                                </div>
                                <div>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {inventoryStatus === null ? <></> : (
                                            <>
                                                <span style={{color: inventoryStatus === "Stocked" ? "green" : inventoryStatus === "Special Order" || customWindows ? "red" : ""}}>{inventoryStatus}</span>
                                                <br />
                                                {customWindows ? <></> : <span><b>SKU:</b> {hit.sku}</span>}
                                            </>
                                        )}
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {customWindows ? (
                                            <>
                                                <span>{customWindowPrice}</span>
                                                <span style={{color: "lightgray"}}>{"$".repeat((5 - (customWindowPrice ?? "").length))}</span>
                                            </>
                                        ) : <></>}
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {stockedVariantsCount > 0 && type === "Color" ? (
                                            <>
                                            <span style={{color: "green"}}>Stocked</span>
                                            <span className='flex-start' style={{whiteSpace:'normal'}}>
                                                <span style={{height: "16px", width: "16px", border: "1px solid #d3d3d3", marginRight: "5px", backgroundColor: stockedColorRgb ?? ""}}></span>
                                                {stockedVariantsCount > 1 ? (
                                                    <span><b> + {stockedVariantsCount - 1} More Colors</b></span>
                                                ) : (
                                                    <span><b>Available in {stockedColor}</b></span>
                                                )} 
                                            </span>
                                            </>
                                        ): <></>}
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        {specialVariantsCount > 0 && type === "Color" ? (
                                            <>
                                                <span style={{color: "red"}}>Special Order</span>
                                                <span className='flex-start'style={{whiteSpace:'normal'}}>
                                                    <span style={{height: "16px", width: "16px", display: "inline-block", border: "1px solid #d3d3d3", marginRight: "5px", backgroundColor: specialColorRgb ?? ""}}></span>
                                                    {specialVariantsCount > 1 ? (
                                                        <span><b> + {specialVariantsCount - 1} More Colors</b></span>
                                                    ) : (
                                                        <span><b>Available in {specialColor}</b></span>
                                                    )} 
                                                </span>
                                            </>
                                        ): <></>}
                                    </Typography>
                                    <Typography>
                                        {stockedVariantsCount > 0 && type === "Measure" ? (
                                            <>
                                                <span style={{color: "green"}}>Stocked</span>
                                                <br />
                                                <span className='measureContainer'>
                                                    <span style={{border: "1px solid #d3d3d3"}}>{stockedMeasure}</span>
                                                    {stockedVariantsCount > 1 ? (
                                                        <span> + {stockedVariantsCount - 1} More Sizes</span>
                                                    ) : (
                                                        <span><b>Available in {stockedMeasure}</b></span>
                                                    )} 
                                                </span>                                         
                                            </>
                                        ): <></>}
                                    </Typography>
                                    <Typography>
                                        {specialVariantsCount > 0 && type === "Measure" ? (
                                            <>
                                                <span style={{color: "red"}}>Special Order</span>
                                                <br />
                                                <span className='measureContainer'>
                                                    <span style={{border: "1px solid #d3d3d3"}}>{specialMeasure}</span>
                                                    {specialVariantsCount > 1 ? (
                                                        <span> + {specialVariantsCount - 1} More Sizes</span>
                                                    ) : (
                                                        <span><b>Available in {specialMeasure}</b></span>
                                                    )} 
                                                </span>
                                            </>
                                        ): <></>}
                                    </Typography>
                                </div>

                            </div>
                            <div style={{height:"50px"}}>
                                <Link className='blueButtonOutline flex-center handCursor' to={ productUrl }>VIEW PRODUCT</Link>
                            </div>
                        </div>
                        )}
                    </Box>
                    {/* <Box sx={{ mt: 2 }}>
                        {loading ? (
                        <Skeleton animation="wave" variant="rectangular" width={100} height={36} sx={{margin:"0 auto"}} />
                        ) : (
                        <Button variant="contained" color="primary">
                            dugme
                        </Button>
                        )}
                    </Box> */}
                    </Box>        

            </Grid>
        );
    }


    const query = useQuery();

    const location = useLocation();
    const [key, setKey] = useState(query.toString());

    useEffect(() => {
        setKey(query.toString());
    }, [location, query]);

    const searchClient = useMemo(() => {
        return algoliasearch(
            process.env.REACT_APP_ALGOLIA_ID ?? "",
            process.env.REACT_APP_ALGOLIA_KEY ?? ""
        );
    }, []);

    let categoryLvl1: string | null = query.get("categorylvl1") ?? null;
    let jobType: string | null = query.get("jobType") ?? null;
    let productType: string | null = query.get("productType") ?? null;
    let searchWindows: boolean = query.get("searchWindows") === "true";
    let categoryName: string | null = searchWindows ? "Windows" : categoryLvl1;
    let queryString: string | null = query.get("query") ?? null;
    let brand: string | null = query.get("brand") ?? null;

    let initialUiStateValue: any = {};

    let refinementList: any = {};
    if (jobType !== null)
        refinementList["jobtype"] = [ jobType ];
    
    if (productType !== null)
        refinementList["producttype"] = [ productType ];

    if (brand !== null)
        refinementList["manufacturername"] = [ brand ];

    let productIndexName: string = process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX ?? "";
    let initialUiState = {
        [productIndexName]: initialUiStateValue,
    };

    if (queryString !== null)
        initialUiStateValue["query"] = queryString;
    initialUiStateValue["refinementList"] = refinementList;

    let isExclusiveCustomer = false;
    let productAttributeName = null;
    let productAttributeValue = null;
    let specificationAttributeName = null;
    let specificationAttributeValue = null;
    let productTagValue = null;

    let searchFilter = "visibleindividually:true";
    if (warehouseCode !== null && warehouseCode !== "" ) {
        searchFilter += ` AND warehouses:${warehouseCode}`;
    }

    if (categoryLvl1 !== null) {
        searchFilter += ` AND categories.lvl1:'${decodeURIComponent(categoryLvl1)}'`;
    }

    if (!isExclusiveCustomer) {
        searchFilter += " AND isexclusiveproduct:false";
    }

    if (searchWindows) {
        searchFilter += " AND customwindows:true";
    }

    if (productAttributeName !== null && productAttributeValue !== null) {
        searchFilter += ` AND productattributes.name:${encodeURIComponent(productAttributeName)} 
            AND productattributes.values:${encodeURIComponent(productAttributeValue)}`;
    }

    if (specificationAttributeName !== null && specificationAttributeValue !== null) {
        searchFilter += ` AND specificationattributes.name:${encodeURIComponent(specificationAttributeName)} 
            AND specificationattributes.option:${encodeURIComponent(specificationAttributeValue)}`;
    }

    if (productTagValue !== null) {
        searchFilter += ` AND tags:${encodeURIComponent(productTagValue)}`;
    }

    let searchOptionalFilters = warehouseCode === null || warehouseCode === "" 
      ? undefined 
      : [
        `sales.lvl1:${warehouseCode}`,
        `sales.lvl2:${warehouseCode}`,
        `sales.lvl3:${warehouseCode}`,
        `sales.lvl4:${warehouseCode}`,
        `sales.lvl5:${warehouseCode}`,
        `sales.lvl6:${warehouseCode}`,
        `sales.lvl7:${warehouseCode}`,
        `sales.lvl8:${warehouseCode}`,
        `sales.lvl9:${warehouseCode}`,
        `sales.lvl10:${warehouseCode}`,
        `sales.lvl11:${warehouseCode}`,
        `sales.lvl12:${warehouseCode}`,
        `sales.lvl13:${warehouseCode}`,
        `sales.lvl14:${warehouseCode}`,
        `sales.lvl15:${warehouseCode}`
      ];

    const TransformCurrentRefinements: CurrentRefinementsProps['transformItems'] = (items) => {
        return items.map(x => {
            if (x.label === "isstocked" && x.refinements.length > 0) {
                x.refinements[0].label = "In Stock";
            }

            return x;
        });
    };
    
    const ManufacturersSortBy: RefinementListProps['sortBy'] = (a, b) => {
        if (categoryLvl1 === "Siding") {
            if (a.isRefined !== b.isRefined) {
                return (b.isRefined ? 1 : 0) - (a.isRefined ? 1 : 0);
            }
            
            if (a.name === 'James Hardie' && b.name !== 'James Hardie')
                return -1;
            else if (a.name !== 'James Hardie' && b.name === 'James Hardie')
                return 1;
            else if (a.name === 'Mastic' && b.name !== 'Mastic')
                return -1;
            else if (a.name !== 'Mastic' && b.name === 'Mastic')
                return 1;
            else if (a.name === 'CertainTeed' && b.name !== 'CertainTeed')
                return -1;
            else if (a.name !== 'CertainTeed' && b.name === 'CertainTeed')
                return 1;
            else if (a.name === 'Westlake Royal Building Products' && b.name !== 'Westlake Royal Building Products')
                return -1;
            else if (a.name !== 'Westlake Royal Building Products' && b.name === 'Westlake Royal Building Products')
                return 1;
            else
                return a.name.localeCompare(b.name);
        }
        else if (categoryLvl1 === "Asphalt Roofing") {
            if (a.isRefined !== b.isRefined) {
                return (b.isRefined ? 1 : 0) - (a.isRefined ? 1 : 0);
            }
            
            if (a.name === 'CertainTeed' && b.name !== 'CertainTeed')
                return -1;
            else if (a.name !== 'CertainTeed' && b.name === 'CertainTeed')
                return 1;
            else if (a.name === 'GAF' && b.name !== 'GAF')
                return -1;
            else if (a.name !== 'GAF' && b.name === 'GAF')
                return 1;
            else
                return a.name.localeCompare(b.name);
        }
        else {
            if (a.isRefined !== b.isRefined) {
                return (b.isRefined ? 1 : 0) - (a.isRefined ? 1 : 0);
            }
            
            return a.name.localeCompare(b.name);
        }
    };

    const ProductTypesSortBy: RefinementListProps['sortBy'] = (a, b) => {
        const BRAND_NAME_TO_BURY:string = 'Other';

        if (a.isRefined !== b.isRefined) {
            return (b.isRefined ? 1 : 0) - (a.isRefined ? 1 : 0);
        } else if (
            (a.name === BRAND_NAME_TO_BURY) !==
            (b.name === BRAND_NAME_TO_BURY)
        ) {
            return (
                (a.name === BRAND_NAME_TO_BURY ? 1 : 0) - (b.name === BRAND_NAME_TO_BURY ? 1 : 0)
            );
        }
        else {
            if (a.count === b.count)
                return a.name.localeCompare(b.name);
            else
                return b.count - a.count;
        }
    };

    const SortByText: RefinementListProps['sortBy'] = (a, b) => {
        if (a.isRefined !== b.isRefined) {
            return (b.isRefined ? 1 : 0) - (a.isRefined ? 1 : 0);
        }
        
        return a.name.localeCompare(b.name);
    };

    const SortByNumeric: RefinementListProps['sortBy'] = (a, b) => {
        if (a.isRefined !== b.isRefined) {
            return (b.isRefined ? 1 : 0) - (a.isRefined ? 1 : 0);
        }
        
        return parseFloat(a.name) - parseFloat(b.name);
    };

    const BrandRefinementList = (props: CustomRefinementListProps) => {
        let [isExpanded, setIsExpanded] = useState<boolean>(!props.collapsedByDefault);
        
        const toggleExpand = () => {
            setIsExpanded(!isExpanded);
        };

        const {
            items,
            refine,
            searchForItems,
            canToggleShowMore,
            isShowingMore,
            toggleShowMore,
          } = useRefinementList(props);
      
          return (
            <>
              <strong onClick={toggleExpand} style={{cursor: "pointer"}}>
                {props.headerText}

                <span className={isExpanded ? "minus-icon" : "plus-icon"}>
                    {isExpanded ? "-" : "+" }
                </span>
              </strong>

              {isExpanded ?
              (
                <>
                    <input
                        type="search"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck={false}
                        maxLength={512}
                        placeholder='Search...'
                        onChange={(event) => searchForItems(event.currentTarget.value)}
                    />
                    <ul>
                        {items.map(x => 
                            (categoryLvl1 === "Siding" && (x.value === 'James Hardie' || x.value === 'Mastic' || x.value === 'CertainTeed' || x.value === 'Westlake Royal Building Products'))
                            || ((categoryLvl1 === "Asphalt Roofing" && (x.value === 'CertainTeed' || x.value === 'GAF')))
                            ? (
                                <li key={x.value}>
                                    <label className="ais-RefinementList-label category-label">
                                        <div>
                                            <input type="checkbox" className="ais-RefinementList-checkbox" checked={x.isRefined} onChange={() => refine(x.value)}></input>
                                            <span className="ais-RefinementList-labelText">{x.value}</span>
                                            <img src={featuredImg} alt="Featured" height={15}></img>
                                        </div>
                                        <span className="ais-RefinementList-count category-count">{x.count}</span>
                                    </label>
                                </li>
                            )
                            : (
                                <li key={x.value}>
                                    <label className="ais-RefinementList-label category-label">
                                        <div>
                                            <input type="checkbox" className="ais-RefinementList-checkbox" checked={x.isRefined} onChange={() => refine(x.value)}></input>
                                            <span className="ais-RefinementList-labelText">{x.value}</span>
                                        </div>
                                        <span className="ais-RefinementList-count category-count">{x.count}</span>
                                    </label>
                                </li>
                            )
                        )}
                    </ul>
                    <button className={canToggleShowMore ? "ais-RefinementList-showMore" : "ais-RefinementList-showMore ais-RefinementList-showMore--disabled"}
                            onClick={toggleShowMore} disabled={!canToggleShowMore}>
                        {isShowingMore ? 'View less' : 'View all'}
                    </button>
                </>
              ) : (
                <></>
              )
              }
              
            </>
          );
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    
    const [open, setOpen] = useState(!isMobile);

    const handleToggle = () => {
      setOpen(!open);
    };
    
    return warehouseCode === null ? <></> : (
        <Grid container spacing={0} sx={{mt:0}} style={{background:"#f8f8f8"}}>            
                <InstantSearch 
                    key={key}
                    searchClient={searchClient} 
                    indexName={process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX} 
                    initialUiState={initialUiState}
                    future={{
                        preserveSharedStateOnUnmount: true
                    }}>

                    <SearchBox style={{display:"none"}} />
                    <Grid item xs={12}>
                        <Configure filters={searchFilter} optionalFilters={searchOptionalFilters}></Configure>

                    </Grid>
                    <Grid item xs={12} >
                        <CustomStats categoryName={categoryName}></CustomStats>

                    </Grid>
                    <Grid item xs={12} sx={{mb:3}}>
                        <div style={{textAlign:"right"}}>
                            <HitsPerPage items={[
                                { label: '12', value: 12, default: true },
                                { label: '24', value: 24 },
                                { label: '48', value: 48 }
                            ]}></HitsPerPage>
                        </div>                        
                    </Grid>
                <Grid item xs={12} md={2} sx={{pr:1}}>
                    <div className='sidebarMenu pgpFacets' style={{background:"white", paddingBottom:"10px", marginBottom:"20px"}}>
                        <Stack direction="row"  justifyContent="space-between"  alignItems="center"  spacing={2}>
                            <b>Filter Product</b>
                            { open ? <CloseIcon onClick={handleToggle} /> : <TuneIcon onClick={handleToggle}/> }                            
                        </Stack>
                        
                        <Collapse in={open}>
                        
                        <CurrentRefinements transformItems={TransformCurrentRefinements}></CurrentRefinements>
                        <ClearRefinements 
                            translations={{
                                resetButtonText: 'Clear all',
                            }}
                        ></ClearRefinements>
                        <ToggleRefinement
                            attribute='isstocked'
                            on={warehouseCode === null || warehouseCode === "" ? "*" : warehouseCode}
                            label='In Stock at your Branch'
                        ></ToggleRefinement>

                        <CustomRefinementList 
                            headerText='Job Type'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={false}
                            attribute='jobtype'
                            showMore={true}
                            showMoreLimit={10000}
                            sortBy={["isRefined", "count:desc", "name:asc"]}
                            limit={10}
                        ></CustomRefinementList>

                        <CustomRefinementList 
                            headerText='Product Type'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={false}
                            attribute='producttype'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={10}
                            sortBy={ProductTypesSortBy}
                        ></CustomRefinementList>

                        <BrandRefinementList 
                            headerText='Brand'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={false}
                            attribute='manufacturername' 
                            showMore={true} 
                            showMoreLimit={10000}
                            sortBy={ManufacturersSortBy}
                            limit={10}
                        ></BrandRefinementList>

                        <ColorFamilyRefinementList
                            headerText='Color Family'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='colorfamily'
                            showMore={true}
                            showMoreLimit={10000}
                            sortBy={["isRefined", "count:desc", "name:asc"]}
                            limit={10}
                        ></ColorFamilyRefinementList>

                        <CustomRefinementList
                            headerText='Profile'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='profile'
                            showMore={true}
                            showMoreLimit={10000}
                            sortBy={SortByText}
                            limit={5}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Siding Profile'
                            searchable={true}
                            searchablePlaceholder='Search...'
                            collapsedByDefault={true}
                            attribute='sidingprofile'
                            showMore={true}
                            showMoreLimit={10000}
                            sortBy={SortByText}
                            limit={5}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Nominal Thickness'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='thickness'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByNumeric}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Length'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='length'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByNumeric}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Height'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='height'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByNumeric}
                        ></CustomRefinementList>
                        
                        <CustomRefinementList
                            headerText='Texture'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='texture'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Product Line'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='productline'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Collection'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='collection'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Roofing Type'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='roofingtype'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByNumeric}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Width'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='width'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByNumeric}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Material'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='materialfilter'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Window Type'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='windowtype'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Product Series'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='productseries'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Frame Color'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='framecolor'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Interior Finish Options'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='interiorfinishoptions'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Exterior Finish Options'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='exteriorfinishoptions'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Installation Type'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='installationtype'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Energy Efficiency'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='energyefficiency'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        <CustomRefinementList
                            headerText='Hardware Color'
                            searchable={false}
                            searchablePlaceholder=''
                            collapsedByDefault={true}
                            attribute='hardwarecolors'
                            showMore={true}
                            showMoreLimit={10000}
                            limit={5}
                            sortBy={SortByText}
                        ></CustomRefinementList>

                        </Collapse>
                        
                    </div>
                </Grid>
                <Grid item  xs={12} md={10}>
                    <Grid container spacing={0} >
                        <CustomHits ></CustomHits>
                    </Grid>
                    <Grid container spacing={0} sx={{padding:"0 20px 0 10px"}}>
                        <Grid item md={12}>
                            <CustomPagination padding={2}></CustomPagination>
                        </Grid>
                    </Grid>
                </Grid>
                    

                </InstantSearch>
            
        </Grid>

    );
}

const CustomPagination = (props: PaginationProps) => {
    const {
        pages,
        currentRefinement,
        nbPages,
        refine
    } = usePagination(props);
  
    return (
        <div className='ais-Pagination'>
            <ul className='ais-Pagination-list'>
                {/* First Page */}                
                <li className={currentRefinement === 0 
                    ? "ais-Pagination-item ais-Pagination-item--firstPage ais-Pagination-item--disabled" 
                    : "ais-Pagination-item ais-Pagination-item--firstPage"}>
                    <a
                        href="#"
                        className='ais-Pagination-link'
                        onClick={(event) => {
                            event.preventDefault();
                            refine(0);
                            window.scrollTo(0, 0);
                        }}
                    >
                        &#171;
                    </a>
                </li>
                {/* Previous Page */}                
                <li className={currentRefinement === 0 
                    ? "ais-Pagination-item ais-Pagination-item--previousPage ais-Pagination-item--disabled"
                    : "ais-Pagination-item ais-Pagination-item--previousPage"}>
                    <a
                        href="#"
                        className='ais-Pagination-link'
                        onClick={(event) => {
                        event.preventDefault();
                        if (currentRefinement > 0)
                            refine(currentRefinement - 1);
                            window.scrollTo(0, 0);
                        }}
                    >
                        &#8249;
                    </a>
                </li>
                {/* Pages */}
                {pages.map((page) => {
                    let itemCLasses:string = "ais-Pagination-item ais-Pagination-item--page";
                    if (currentRefinement === page)
                        itemCLasses += " ais-Pagination-item--selected";

                    return (<li key={page} className={itemCLasses}>
                        <a
                            href="#"
                            className="ais-Pagination-link"
                            onClick={(event) => {
                                event.preventDefault();
                                refine(page);
                                window.scrollTo(0, 0);
                            }}
                            >
                            {page + 1}
                            </a>
                    </li>
                    )
                })}
                {/* Next Page */}                
                <li className={currentRefinement === nbPages - 1 
                    ? "ais-Pagination-item ais-Pagination-item--nextPage ais-Pagination-item--disabled"
                    : "ais-Pagination-item ais-Pagination-item--nextPage"
                }>
                    <a
                        href="#"
                        className='ais-Pagination-link'
                        onClick={(event) => {
                        event.preventDefault();
                        if (currentRefinement < nbPages - 1)
                            refine(currentRefinement + 1);
                            window.scrollTo(0, 0);
                        }}
                    >
                        &#8250;
                    </a>
                </li>
                {/* Last Page */}
                <li className={currentRefinement === nbPages - 1
                    ? "ais-Pagination-item ais-Pagination-item--lastPage ais-Pagination-item--disabled"
                    : "ais-Pagination-item ais-Pagination-item--lastPage"
                }>
                    <a
                        href="#"
                        className='ais-Pagination-link'
                        onClick={(event) => {
                            event.preventDefault();
                            refine(nbPages - 1);
                            window.scrollTo(0, 0);
                        }}
                    >
                        &#187;
                    </a>
                </li>
            </ul>
        </div>
    );
}

const CustomRefinementList: React.FC<CustomRefinementListProps> = ({ headerText, collapsedByDefault, ...props }) => {
    let [isExpanded, setIsExpanded] = useState<boolean>(!collapsedByDefault);
    
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const {
        items
    } = useRefinementList(props);

    if (items.length === 0)
        return null;

    return (
        <>
            <strong onClick={toggleExpand} style={{cursor: "pointer"}}>
                {headerText}

                <span className={isExpanded ? "minus-icon" : "plus-icon"}>
                    {isExpanded ? "-" : "+" }
                </span>
            </strong>

            {isExpanded ? 
            (
                <RefinementList 
                    {...props}
                    searchable={props.searchable}
                    translations={{
                        showMoreButtonText({ isShowingMore }) { 
                            return isShowingMore ? 'View Less' : 'View All';
                        }
                }}></RefinementList>
            )
            : (
                <></>
            )}
        </>
    );
};

const ColorFamilyRefinementList = (props: CustomRefinementListProps) => {
    let [isExpanded, setIsExpanded] = useState<boolean>(!props.collapsedByDefault);
        
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    let [hexColorsMapping, setHexColorsMapping] = useState<Dictionary>({});

    const {
        items,
        refine,
        canToggleShowMore,
        isShowingMore,
        toggleShowMore,
    } = useRefinementList(props);

    useEffect(() => {
        axios.get(VersionUrl(process.env.REACT_APP_API_URL + "/GetHexToColorMapping"))
        .then(response => {
            setHexColorsMapping(response.data.hexToColorMapping);
        })
        .catch(error => {
            console.error(error);
            setHexColorsMapping({
                Black: "#000000",
                Blue: "#6fa8dc",
                Bronze: "#744700",
                Brown: "#744700",
                Gray: "#999999",
                Green: "#38761d",
                Orange: "#f6b26b",
                Primed: "#fffff2",
                Red: "#d24d4d",
                White: "#ffffff",
                Yellow: "#f1c232"
            });
        });
    }, []);

    if (items.length === 0)
        return null;

    return (
        <>
            <strong onClick={toggleExpand} style={{cursor: "pointer"}}>
                {props.headerText}

                <span className={isExpanded ? "minus-icon" : "plus-icon"}>
                    {isExpanded ? "-" : "+" }
                </span>
            </strong>
            {isExpanded ?
            (
                <div className='colorSelector'>
                    <ul>
                    {items.map(x => 
                        (
                            <div key={x.value}  className={x.isRefined ? "swatch-wrapper l-hmargin--small selected" : "swatch-wrapper l-hmargin--small" }>
                                <input type="checkbox" className="ais-RefinementList-checkbox" checked={x.isRefined} onChange={() => refine(x.value)}></input>
                                <span className="swatch" title={x.value} style={{backgroundColor: hexColorsMapping[x.value]}} onClick={() => refine(x.value)}>&nbsp;</span>                            
                                <span onClick={() => refine(x.value)}>{x.value} ({x.count})</span>
                            </div>
                        )
                    )}
                    </ul>
                    <div style={{clear: "both"}}></div>
                    <button 
                        className={canToggleShowMore ? "ais-RefinementList-showMore" : "ais-RefinementList-showMore ais-RefinementList-showMore--disabled"}
                        onClick={toggleShowMore} disabled={!canToggleShowMore}>
                        {isShowingMore ? 'View less' : 'View all'}
                    </button>
                </div>
            ) : (
                <></>
            )}
            
        </>
    );
};


const CustomStats = ({ categoryName }: GalleryCustomStatsProps) => {
    const { nbHits } = useStats();
    let countLabel = nbHits === 1 ? "Product" : "Products";
    return (
        <div className='text-center page-margin'>
            {categoryName === null ? <div style={{paddingTop: "40px"}}></div> : (
                <div className='page-title'>
                    <h1>{categoryName}</h1>
                </div>
            )}
            <span className='itemCountText'>
                {nbHits.toLocaleString()} {countLabel} {categoryName === null ? "" : "in"} <b>{categoryName}</b>
            </span>
        </div>
    );
}

export default ProductGallery;
