import { Box, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import ProductSpecificationTabsProps from "../../interfaces/product/ProductSpecificationTabsProps";
import React, { useState, useRef } from 'react';
import VersionUrl from "../../utils/versionUrl"

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}


const ProductSpecificationTabs:React.FC<ProductSpecificationTabsProps> = ({ brand, aboutText, embeddedVideo, specifications, documents, loading }) => {    
    const [value, setValue] = useState<number>(0);
    
    const scrollToAboutRef = useRef<HTMLDivElement | null>(null);
    const scrollToSpecificationsRef = useRef<HTMLDivElement | null>(null);
    const scrollToDocumentsRef = useRef<HTMLDivElement | null>(null);
  
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);

        setTimeout(() => {            
            if (newValue === 0 && scrollToAboutRef !== null && scrollToAboutRef.current !== null)
                scrollToAboutRef.current.scrollIntoView({ behavior: 'smooth' });  
            
            if (newValue === 1 && scrollToSpecificationsRef !== null && scrollToSpecificationsRef.current !== null)
                scrollToSpecificationsRef.current.scrollIntoView({ behavior: 'smooth' });
            
            if (newValue === 2 && scrollToDocumentsRef !== null && scrollToDocumentsRef.current !== null)
                scrollToDocumentsRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 150);
    };

    let aboutVisible = (aboutText !== null && aboutText !== "") || (embeddedVideo !== null && embeddedVideo !== "");
    let specificationsVisible = specifications.length > 0;
    let documentsVisible = documents.length > 0;
    let aboutTabIndex = 0;
    let specificationsTabIndex = aboutVisible ? 1 : 0;
    let documentsTabIndex = aboutVisible && specificationsVisible ? 2 : (aboutVisible || specificationsVisible ? 1 : 0);


    return (
        <Box sx={{ width: '100%', mb:0 }}>
            <Box sx={{ borderTop: 1, borderColor: 'divider', mt:8, pt:5, mb:2 }}>
                <Tabs value={value} onChange={handleTabChange}  sx={{mb:4}} >
                    {aboutVisible && <Tab sx={{fontSize:"16px"}} label="About" {...a11yProps(aboutTabIndex)} ref={scrollToAboutRef} />}
                    {specificationsVisible && <Tab sx={{fontSize:"16px"}} label="Specifications" {...a11yProps(specificationsTabIndex)} ref={scrollToSpecificationsRef} />}
                    {documentsVisible && <Tab sx={{fontSize:"16px"}} label="Documents" {...a11yProps(documentsTabIndex)} ref={scrollToDocumentsRef} />}
                </Tabs>
            </Box>
            {aboutVisible ? (
                <CustomTabPanel value={value} index={aboutTabIndex}>
                    <div className='tabAboutList'>
                        {loading ? 
                        <Skeleton variant="rectangular" animation="wave" height={80} /> : 
                        <>
                            <div className="content" dangerouslySetInnerHTML={{__html: aboutText ?? ""}}></div>
                            <div dangerouslySetInnerHTML={{__html: embeddedVideo ?? ""}}></div>
                        </>
                        }  
                    </div>
                </CustomTabPanel>
            ) : <></>}
            {specificationsVisible ? (
                <CustomTabPanel value={value} index={specificationsTabIndex}>
                    {loading ? <Skeleton variant="rectangular" animation="wave" height={80} /> : 
                    <>
                    {
                        specifications.map(specification => (
                            <div className='tabSpecificationList' key={specification.name}>
                                    <span>{specification.name}:</span>
                                    <b>{specification.value}</b>
                            </div>
                        ))
                    }        
                    </>}                                
                </CustomTabPanel>
            ) : <></>}
            {documentsVisible ? (
                <CustomTabPanel value={value} index={documentsTabIndex}>
                    <div className='tabSpecificationList'>
                        {loading ? <Skeleton variant="rectangular" animation="wave" width={"100%"} /> :
                        <><span>Brand:</span><b>{brand}</b></>}
                    </div>
                    {loading ? <Skeleton variant="rectangular"animation="wave" width={"100%"} height={80} /> :
                    <>
                        {
                            documents.map(document => (
                                <div key={document.fileName}className='tabSpecificationList'>
                                    <a href={VersionUrl(process.env.REACT_APP_API_URL + "/GetProductAttachment") + "&id=" + document.attachmentId} target='_blank' rel="noreferrer">{document.fileName}</a>
                                </div>
                            ))
                        }
                    </>
                    }
                    <Typography variant="caption" display="block" gutterBottom style={{marginTop:"50px"}}>
                        {loading ? <Skeleton /> : <>To read PDF files, you need the Adobe Acrobat Reader 6.0 or higher. <a href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer">Click here</a> to download it for free from Adobe's site.</>}
                    </Typography>
                </CustomTabPanel>
            ) : <></>}
        </Box>
    );
} 

export default ProductSpecificationTabs;