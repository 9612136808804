import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import WarehouseProps from '../../interfaces/common/WarehouseProps';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color:"#54afe2",
    fontFamily:"Montserrat",
    fontWeight:"600",
    '&:hover': {
        color: blue[700],
    },
}));

const QuickLinks:React.FC<WarehouseProps> = ({ warehouseCode }) => {
    return (
        <>
            <Grid container spacing={2} sx={{my:5}}>
                <Grid item xs={12}>
                    <h2 className="lansingDarkColor m-0">Quick Links</h2>
                </Grid>
                <Grid item md={3}>
                    <div>
                        <Link className='noUnderlineLink' to={"/programs/clearwin"}>
                            <ColorButton variant="text">Clearwin »</ColorButton>
                        </Link>                        
                    </div>
                    <div>
                        <Link className='noUnderlineLink' to={process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/ProductList/Public")}>
                            <ColorButton variant="text">Product Templates »</ColorButton>
                        </Link>
                    </div>
                    <div>
                        <Link className='noUnderlineLink' to={"/programs/lansinggreen"}>
                            <ColorButton variant="text">Lansing Green Financing »</ColorButton>
                        </Link>
                    </div>
                    <div>
                        <ColorButton variant="text" href="https://lansingbp.com/locations/">Locations »</ColorButton>
                    </div>
                </Grid>
                <Grid item  md={3}>
                    <div>
                        <Link className='noUnderlineLink' to={"/catalog"}>
                            <ColorButton variant="text">Shop Products »</ColorButton>
                        </Link>
                    </div>
                    <div>
                        <Link className='noUnderlineLink' to={process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/customer/info")}>
                            <ColorButton variant="text">My Account »</ColorButton>
                        </Link>
                    </div>
                    <div>
                        <Link className='noUnderlineLink' to={process.env.REACT_APP_WEB_URL + "/common/contactus?warehouseCode=" + warehouseCode}>
                            <ColorButton variant="text" className='linkButtons'>Contact »</ColorButton>
                        </Link>
                        
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default QuickLinks;