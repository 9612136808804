import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import PageTitle from "../../utils/pageTitle";
import clearwinBannerImg from '../../img/program-clearwin-banner.jpeg';
import Stack from '@mui/material/Stack';
import SeoIgnore from "../../utils/seoIgnore";

const Clearwin = () => {
    PageTitle("Clearwin");   // Set page title

    let startGuideUrl: string = process.env.REACT_APP_WEB_URL + "/images/uploaded/ClearWinQuickStartGuide.pdf";
    
    return (
        <Grid>
            <SeoIgnore></SeoIgnore>
            <div className='cp_backgroundImage' style={{backgroundImage: `url(${clearwinBannerImg})`}}>
                <Container maxWidth="lg">
                    <h1>ClearWin</h1>
                </Container>                  
            </div>
            <Container maxWidth="lg" sx={{my:5}}>
                <Grid container spacing={0} direction="column">
                    <p className='customerProgramsFont'>
                        Lansing Building Products is the premier distributor of Harvey Windows + Doors in the northeast. Lansing customers benefit from this partnership by having exclusive access to Harvey's 
                        ClearWin quoting and ordering system. With ClearWin, you can easily and quickly price out jobs and place orders, plus create 
                        window or entry door quotes for your customers with your markup.
                    </p>

                    <p className='customerProgramsFont'>
                        <strong>
                            YOU MUST HAVE AN EXISTING CLEARWIN ACCOUNT TO PROCEED. <br />
                        </strong>
                    </p>

                    <Stack direction="row" spacing={2} sx={{mb:5}}>
                        <a className='blueButtonOutline' href="https://harvey.wtsparadigm.com/login">Access ClearWin</a>
                    </Stack>  

                    <h3 className='m-0'>ClearWin Resources</h3>

                    <p className='customerProgramsFont'>Please use these helpful resources to get you started using ClearWin.</p>

                    <Stack direction="row" spacing={1} alignItems="center" >
                        <img src="https://lansingnow-uat.profitoptics.com/images/uploaded/file.png"/>
                        <a  href={startGuideUrl} target="_blank" rel="noreferrer">
                             Download ClearWin Quick Start Guide
                        </a>
                    </Stack>  
                    

                </Grid>
            </Container>
        </Grid>
    );
}

export default Clearwin;