import HomePageCategories from "../homepage/homepageCategories"
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import PageTitle from "../../utils/pageTitle";
import SeoIgnore from "../../utils/seoIgnore";

const Catalog = () => {
    
    PageTitle("Catalog");   // Set page title
    
    return (
        <Container maxWidth="lg" sx={{my:5}}>
            <SeoIgnore></SeoIgnore>
            <h1>Categories</h1>
            <Grid container spacing={1}>
                <HomePageCategories />
            </Grid>
        </Container>
    );
};

export default Catalog;