import React, { useEffect } from 'react';

const PingdomComponent = () => {
  useEffect(() => {
    let env = process.env.REACT_APP_ENV;

    if (env === 'production') {
        const script = document.createElement('script');
        script.src = "//rum-static.pingdom.net/pa-670d41497a5fd40012000073.js";
        script.async = true;

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }
  }, []);

  return null; // This component doesn't render anything to the UI
};

export default PingdomComponent;
