import MainMenuCategories from "./mainMenuCategories";
import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, Box, Drawer, List, ListItem, ListItemText, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '../../img/menuIcon.svg';
import QuickSearch from './quickSearch';
import logoImg from '../../img/logo.svg';
import accountImg from '../../img/icon_account.svg';
import cartImg from '../../img/icon_cart.svg';
import helpImg from '../../img/icon_help.svg';
import searchImgMob from '../../img/search2.svg';
import orderImg from '../../img/icon_order.png';
import programsImg from '../../img/icon_programs.png';
import templatesImg from '../../img/icon_template.png';
import MainMenuProps from "../../interfaces/navbar/MainMenuProps";


const MainMenu: React.FC<MainMenuProps> = ({ warehouseCode, warehouseName, isNEDivision }) => {
    const [activeMenu, setActiveMenu] = useState<null | number>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const closeMenuTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const handleMenuOpen = (menuId: number) => (event: React.MouseEvent<HTMLElement>) => {
        // Focus menu on open (to avoid anchoring other elements on the page)
        document.body.setAttribute('tabindex', '-1'); // Ensure body is focusable
        document.body.focus();  // Focus on the body when hovering the menu

        // Clear any existing timeout
        clearTimeout(closeMenuTimeoutRef.current!);

        setActiveMenu(menuId);

        if (menuId !== 0) {
            document.body.classList.remove('menuFullWidth');
        }
    };

    const handleMenuClose = (menuId: number, forceClose: boolean) => {
        closeMenuTimeoutRef.current = setTimeout(() => {
            if (activeMenu === menuId) {
                // Prevent closing when Category submenu item is clicked
                if (forceClose || menuId !== 0) {
                    setActiveMenu(null);
                    document.body.classList.remove('menuFullWidth');
                }
            }
        }, 150);  // Add a slight delay before closing the menu
    };

    const cancelCloseMenu = () => {
        // Cancel closing if user hovers back in
        clearTimeout(closeMenuTimeoutRef.current!);  
    };

    const handleSubcategoryOpened = (opened: boolean) => {
        if (opened) {
            document.body.classList.add('menuFullWidth');
        } else {
            document.body.classList.remove('menuFullWidth');
        }
    }

    const renderMenu = (menuId: number, anchorEl: HTMLElement | null, items: { label: string, link: string }[]) => (
        <Menu
            className="mainMenuExpand"
            anchorEl={anchorEl}
            open={activeMenu === menuId}
        >
            {items.map((item, index) => (
                <div key={index}>
                    <MenuItem sx={{ color: "#494440" }} 
                        onClick={() => handleMenuClose(menuId, false)}
                        onMouseEnter={cancelCloseMenu}
                        onMouseLeave={() => handleMenuClose(menuId, true)}>
                        {menuId === 0 && activeMenu === 0
                        ? (
                            <MainMenuCategories warehouseCode={warehouseCode} onSubcategoryOpened={handleSubcategoryOpened} />
                        ) 
                        : menuId === 0 && activeMenu !== 0
                        ? (
                            <></>
                        ) : (
                            <Link to={item.link}>{item.label}</Link>
                        )}
                    </MenuItem>
                </div>
            ))}
        </Menu>
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setActiveMenu(null);
        document.body.classList.remove('menuFullWidth');
        setIsDrawerOpen(false);
        setIsOpen(false);
    }, [location]);

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        border:"0px solid pink",
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const toggleDrawer = (open: any) => (event: any) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setIsDrawerOpen(open);
    };
    
    const mobileMenuItems = () => (
        <div role="presentation">
          <List sx={{p:3}}>
            <ListItem>
                <Link to={process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/order/history")}>
                    <ListItemText className="sidebarIconLink"><img src={orderImg} alt="MY ORDERS & QUOTES"></img> MY ORDERS & QUOTES</ListItemText>
                </Link>
            </ListItem>
            <ListItem>
                <Link to={process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/ProductList/Public")}>
                    <ListItemText className="sidebarIconLink"><img src={templatesImg} alt="TEMPLATES & ESTIMATES"></img> TEMPLATES & ESTIMATES</ListItemText>
                </Link>
            </ListItem>
            <ListItem>
                <Link to={process.env.REACT_APP_WEB_URL + "/quicklogin"}>
                    <ListItemText className="sidebarIconLink"><img src={programsImg} alt="CUSTOMER PROGRAMS"></img> CUSTOMER PROGRAMS</ListItemText>
                </Link>
            </ListItem>
            <ListItem>
                <ListItemText><h2>Shop Products</h2></ListItemText>
            </ListItem>
            <ListItem>
                <div className="mobileMenuItems">
                    {isDrawerOpen ? <MainMenuCategories warehouseCode={warehouseCode} onSubcategoryOpened={handleSubcategoryOpened} /> : <></>}
                </div>
            </ListItem>
          </List>
        </div>
      );

    //MOBILE SEARCH
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    let impactRewardsBranches: string[] = (process.env.REACT_APP_IMPACT_REWARDS_BRANCHES ?? "").split(',').map(item => item.toLowerCase());
    let hasImpactRewards: boolean = impactRewardsBranches.includes((warehouseCode ?? "").toLowerCase());

    let customerProgramsMenuItems = [
        { label: "ProZone", link: "/programs/prozone"},
        { 
            label: hasImpactRewards ? "Impact Rewards" : "ProBucks", 
            link: hasImpactRewards ? "/programs/impactrewards" : "/programs/probucks"
        },
        { label: "SAVR", link: "/programs/savr" },
        { label: "Marketing Services", link: "/programs/marketingservices" },
        { label: "Lansing Green", link: "/programs/lansinggreen" }
    ];

    let helpMenuItems = [
        { label: "Contact us", link: process.env.REACT_APP_WEB_URL + "/common/contactus?warehouseCode=" + warehouseCode },
        { label: "FAQ", link: process.env.REACT_APP_WEB_URL + "/faq" },
        { label: "Return Policy", link: process.env.REACT_APP_WEB_URL + "/return-privacy" },
    ];

    if (!isNEDivision)
        customerProgramsMenuItems = customerProgramsMenuItems.filter(x => 
            x.label !== "ProZone" && (hasImpactRewards || (x.label !== "ProBucks" && x.label !== "Impact Rewards")));

    return (
        <>
        <AppBar position="static" 
            sx={{ 
                backgroundColor: 'white',
                borderBottom: "1px solid #eaeaea",
                px: 0,
                py: 1,
                }}
                elevation={0}>
            <Toolbar>

                {isMobile ? (
                    <>
                        <div>
                            <IconButton
                                
                                edge="start"
                                color="inherit"
                                sx={{ color: "#939393"}}
                                aria-label="menu"
                                onClick={toggleDrawer(true)}>
                                <img  src={MenuIcon} alt="Menu" />
                            </IconButton>
                            <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                                <DrawerHeader>
                                    <IconButton onClick={toggleDrawer(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </DrawerHeader>
                                {mobileMenuItems()}
                            </Drawer>
                        </div>
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            <Link to={"/"}>
                                <img src={logoImg} alt='Lansing Logo' height={40}></img>
                            </Link>
                        </Typography>                        
                        <Stack direction="row" justifyContent="flex-end" alignItems="center"spacing={2}>
                            <Button  onClick={handleToggle} sx={{minWidth:"25px", p:0}} variant="text">
                                <img src={searchImgMob} alt="Search" />
                            </Button>
                            <Button
                                className="hide-on-mob mainMenuButtonThumbs" 
                                onClick={handleMenuOpen(4)}
                                onMouseEnter={cancelCloseMenu}
                                onMouseLeave={() => handleMenuClose(4, true)}
                                sx={{
                                    padding: 0,
                                    minWidth: 'auto',
                                    width: 'auto',
                                    height: 'auto',
                                }}>
                                <img src={helpImg} alt="Help"></img>
                            </Button>
                            {renderMenu(4, activeMenu === 4 ? document.querySelector('.mainMenuButtonThumbs') : null, helpMenuItems)}
                            {/* <Link to={process.env.REACT_APP_WEB_URL + "/quicklogin"}>
                                <img src={accountImg} alt="Account"></img>
                            </Link>
                            <Link to={process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/cart")}>
                                <img src={cartImg} alt="Cart"></img>
                            </Link> */}
                            <Button className="blueButtonOutline" style={{marginLeft:"20px"}} href={process.env.REACT_APP_WEB_URL + "/quicklogin" }>LOG IN</Button>                            
                        </Stack>
                    </>
                ) : (
                    <>
                        <Typography variant="h6" sx={{ flexGrow: 1, zIndex: "1500" }}>
                            <Link to={"/"}>
                                <img src={logoImg} alt='Lansing Logo'></img>
                            </Link>
                        </Typography>
                        <Box sx={{ flexGrow: 2, textAlign: "center", zIndex: "1500" }}>
                            <Button color="inherit" className="mainMenuButtons mainMenuButtonsResponsive"
                                onMouseOver={handleMenuOpen(0)}
                                onMouseEnter={cancelCloseMenu}
                                onMouseLeave={() => handleMenuClose(0, true)}>
                                Shop Products
                            </Button>
                            {renderMenu(0, activeMenu === 0 ? document.querySelector('.mainMenuButtons:nth-child(1)') : null, [
                                { label: "Categories", link: "" }
                            ])}
                            <Button color="inherit" className={`mainMenuButtons mainMenuButtonsResponsive ${activeMenu === 1 ? "button-active" : ""}`}
                                onMouseOver={handleMenuOpen(1)}
                                onMouseEnter={cancelCloseMenu}
                                onMouseLeave={() => handleMenuClose(1, true)}>
                                Customer Programs
                            </Button>
                            {renderMenu(1, activeMenu === 1 ? document.querySelector('.mainMenuButtons:nth-child(2)') : null, customerProgramsMenuItems)}

                            <Button color="inherit" className={`mainMenuButtons mainMenuButtonsResponsive ${activeMenu === 2 ? "button-active" : ""}`}
                                onMouseOver={handleMenuOpen(2)}
                                onMouseEnter={cancelCloseMenu}
                                onMouseLeave={() => handleMenuClose(2, true)}>
                                Templates & Estimates
                            </Button>
                            {renderMenu(2, activeMenu === 2 ? document.querySelector('.mainMenuButtons:nth-child(3)') : null, [
                                { label: "Templates", link: process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/ProductList/Public") },
                                { label: "Homeowner Estimates", link: process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/Quote/List") },
                            ])}

                            <Button color="inherit" className={`mainMenuButtons mainMenuButtonsResponsive ${activeMenu === 3 ? "button-active" : ""}`}
                                onMouseOver={handleMenuOpen(3)}
                                onMouseEnter={cancelCloseMenu}
                                onMouseLeave={() => handleMenuClose(3, true)}>
                                My Orders & Quotes
                            </Button>
                            {renderMenu(3, activeMenu === 3 ? document.querySelector('.mainMenuButtons:nth-child(4)') : null, [
                                { label: "Orders", link: process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/order/history?tab=orders") },
                                { label: "Open Quotes", link: process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/order/history?tab=quotes") },
                            ])}
                        </Box>
                        <Box sx={{ flexGrow: 1, maxWidth:"300px", zIndex: "1500" }}>
                            <QuickSearch warehouseName={warehouseName} warehouseCode={warehouseCode} />
                        </Box>
                        <Box sx={{ flexGrow: 1, textAlign: "right", zIndex: "1500" }}>
                            <Button color="inherit" className="mainMenuButtonThumbs" 
                                onClick={handleMenuOpen(4)}
                                onMouseEnter={cancelCloseMenu}
                                onMouseLeave={() => handleMenuClose(4, true)}>
                                <div style={{ display:"flex", flexDirection:"column" }}>
                                    <img src={helpImg} width={23} height={23} style={{margin: "0 auto"}} alt="Help"></img>    
                                    <span style={{color: "#494440",fontSize: "11px", textTransform:"none"}}>Help</span>
                                </div>
                            </Button> 
                            {renderMenu(4, activeMenu === 4 ? document.querySelector('.mainMenuButtonThumbs') : null, helpMenuItems)}
                            <Button className="blueButtonOutline"
                                style={{marginLeft:"10px", marginTop:"-5px"}} 
                                href={process.env.REACT_APP_WEB_URL + "/quicklogin"}>LOG IN</Button>
                            {/* <Button color="inherit" href={process.env.REACT_APP_WEB_URL + "/quicklogin"}>
                                <div style={{ display:"flex", flexDirection:"column" }}>
                                    <img src={accountImg} width={23} height={23} style={{margin: "0 auto"}} alt="Account"></img>    
                                    <span style={{color: "#494440",fontSize: "11px", textTransform:"none"}}>Account</span>
                                </div>
                            </Button>

                            <Button color="inherit" href={process.env.REACT_APP_WEB_URL + "/quicklogin?returnUrl=" + encodeURIComponent("/cart")}>
                                <div style={{ display:"flex", flexDirection:"column" }}>
                                    <img src={cartImg} width={23} height={23} style={{margin: "0 auto"}} alt="Cart"></img>    
                                    <span style={{color: "#494440",fontSize: "11px", textTransform:"none"}}>Cart</span>
                                </div>
                            </Button> */}
                        </Box>
                    </>
                )}
            </Toolbar>
        </AppBar>

        {isOpen && (
            <Box mt={0} p={2} border={0}>
                <QuickSearch warehouseName={warehouseName} warehouseCode={warehouseCode} />
            </Box>
        )}


        </>
    );
}

export default MainMenu;
