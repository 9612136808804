import React, { useEffect, useState } from "react";
import WarehouseSelectorProps from "../../interfaces/header/WarehouseSelectorProps";
import MapComponentProps from "../../interfaces/common/MapComponentProps";
import { AdvancedMarker, APIProvider, InfoWindow, Map } from '@vis.gl/react-google-maps';
import bluePinImg from '../../img/pin-blue.png';
import grayPinImg from '../../img/pin-gray.png';
import MapTypeEnum from "../../enums/mapTypeEnum";

const MapComponent: React.FC<MapComponentProps> = ({ mapKey, warehouses, selectedWarehouseCode, longitudeCenterOffset, 
  disableControls, onMapLocationSelected, searchQuery, mapType }) => {

  const [selectedWarehouseOnMap, setSelectedWarehouseOnMap] = useState<WarehouseSelectorProps | null>(null);
  const [selectedLatitude, setSelectedLatitude] = useState<number | null>(null);
  const [selectedLongitude, setSelectedLongitude] = useState<number | null>(null);

  useEffect(() => {
    if (mapType === MapTypeEnum.UseMyCurrentLocation) {
      navigator.geolocation.getCurrentPosition(function(res) {
        setSelectedLatitude(res.coords.latitude);
        setSelectedLongitude(res.coords.longitude);

        onMapLocationSelected(res.coords.latitude, res.coords.longitude);
      });
    }
    else if (mapType === MapTypeEnum.SearchText && searchQuery !== null && searchQuery !== '') {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: searchQuery }, (results, status) => {
        if (status === 'OK' && results !== null && results[0]) {
          const location = results[0]?.geometry.location;
          if (location) {
            onMapLocationSelected(location.lat(), location.lng());
            setSelectedLatitude(location.lat());
            setSelectedLongitude(location.lng());
          }
        } else {
          onMapLocationSelected(null, null);
          console.error("Error finding location. ");
        }
      });
    }
    else {    
      let lat = warehouses.find(x => x.warehouseCode === selectedWarehouseCode)?.latitude;
      let lng = warehouses.find(x => x.warehouseCode === selectedWarehouseCode)?.longitude;

      if (lat != null)
        setSelectedLatitude(lat);

      if (lng != null)
        setSelectedLongitude(lng);
    }
  }, [warehouses, selectedWarehouseCode, mapType, searchQuery]);

  return selectedWarehouseCode == null || selectedLatitude == null || selectedLongitude == null ? <></> : (
    <>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""}>
        <div className="mapholderModal">
          <Map
            key={mapKey + selectedLatitude + selectedLongitude }
            mapId={mapKey}
            defaultZoom={10}
            defaultCenter={{ lat: selectedLatitude, lng: selectedLongitude + longitudeCenterOffset }}
            disableDefaultUI={disableControls}
            zoomControl={!disableControls}
            fullscreenControl={!disableControls}
            disableDoubleClickZoom={disableControls}
            gestureHandling={disableControls ? "none" : null}
            renderingType='UNINITIALIZED'
            style={{ width: '100%', minHeight: '80%' }}
            >
                  {warehouses.map((warehouse: WarehouseSelectorProps) => (
                    <AdvancedMarker
                      key={warehouse.warehouseCode}
                      position={{lat: warehouse.latitude ?? 0, lng: warehouse.longitude ?? 0}}
                      onClick={() => {
                        if (!disableControls)
                          setSelectedWarehouseOnMap(warehouse);
                      }}>
                        <img
                          src={warehouse.warehouseCode === selectedWarehouseCode ? bluePinImg : grayPinImg}
                          alt="Map Pin"
                          style={{ width: "50px", height: "50px", cursor: disableControls ? "default" : "pointer" }}
                        />                      
                    </AdvancedMarker>
                  ))}
              
              {selectedWarehouseOnMap && (
                <InfoWindow
                  position={{
                    lat: selectedWarehouseOnMap.latitude ?? 0,
                    lng: selectedWarehouseOnMap.longitude ?? 0,
                  }}
                  onCloseClick={() => {
                    setSelectedWarehouseOnMap(null);
                  }}
                >
                  <div>
                    <h2>{selectedWarehouseOnMap.warehouseName}</h2>
                    <p>{selectedWarehouseOnMap.warehouseFullAddress}</p>
                  </div>
                </InfoWindow>
              )}
          </Map>
        </div>
      </APIProvider>
    </>
  );
};

export default MapComponent;
