import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import PageTitle from "../../utils/pageTitle";
import proZoneBannerImg from '../../img/program-prozone-banner.jpg';
import Stack from '@mui/material/Stack';
import SeoIgnore from "../../utils/seoIgnore";

const ProZone = () => {
    PageTitle("ProZone Referral Program");   // Set page title

    let applyNowUrl: string = process.env.REACT_APP_WEB_URL + `/quicklogin?returnUrl=${encodeURIComponent("/ProZoneWidget/NewApplicant")}`;
    
    return (
        <Grid>
            <SeoIgnore></SeoIgnore>
            <div className='cp_backgroundImage' style={{backgroundImage: `url(${proZoneBannerImg})`}}>
                <Container maxWidth="lg">
                    <h1>ProZone Referral Program</h1>
                </Container>
            </div>
            <Container maxWidth="lg" sx={{my:5}}>
                <Grid  container spacing={0} direction="column">
                    <h3>
                        Program Overview
                    </h3>
                    <p className='customerProgramsFont'>
                        Grow your business with free leads from our ProZone Contractor Referral program! ProZone is our way of connecting interested homeowners with experienced professional contractors like yourself.
                    </p>
                    <p className='customerProgramsFont'>
                        Apply below and set up your profile. Once approved, your profile will be visible to homeowners and leads will be sent to your email in your profile.*
                    </p>
                    <Stack direction="row" spacing={2} sx={{my:4}}>
                        <a className='blueButtonOutline' href={applyNowUrl} rel="noreferrer">APPLY NOW  &#187;</a>
                    </Stack>
                    <p className='customerProgramsFont' style={{fontSize:"80%"}}>
                        <i>
                        * Disclaimer: In order to qualify, minimum sales criteria by product category must be met and your account must be in good credit standing. Lansing Building Products reserves the right to deny or discontinue a contractor’s participation in the ProZone Contractor Referral Program at any time, for any reason.&nbsp;
                        </i>
                    </p>
                </Grid>
            </Container>
        </Grid>
    );
}

export default ProZone;