import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import WarehouseDialogProps from '../../interfaces/header/WarehouseDialogProps';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { Typography, Grid } from '@mui/material';
import axios from 'axios';
import  { useState, useEffect  } from 'react';
import WarehouseSelectorProps from '../../interfaces/header/WarehouseSelectorProps';
import MapComponent from '../common/mapComponent';
import VersionUrl from "../../utils/versionUrl"
import TextField from '@mui/material/TextField';
import MapTypeEnum from '../../enums/mapTypeEnum';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const WarehouseDialog: React.FC<WarehouseDialogProps> = ({ open, onClose, warehouseCode, 
    warehouseName, onWarehouseSelected }) => {
    let [allWarehouses, setAllWarehouses] = useState<WarehouseSelectorProps[]>([]);
    let [currentWarehouse, setCurrentWarehouse] = useState<WarehouseSelectorProps | null>();
    let [otherBranches, setOtherBranches] = useState<GridRowsProp>([]);
    const [searchTmpQuery, setSearchTmpQuery] = useState<string>('');
    const [searchMapQuery, setSearchMapQuery] = useState<string>('');
    const [mapType, setMapType] = useState<MapTypeEnum>(MapTypeEnum.CurrentBranch);
    const [showValidationMessage, setShowValidationMessage] = useState(false);

    useEffect(() => {
        if (showValidationMessage) {
            const timer = setTimeout(() => {
                setShowValidationMessage(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [showValidationMessage]);

    const handleWarehouseSelect = (newWarehouseCode: string) => {
        onWarehouseSelected(newWarehouseCode);
        handleDialogClose();
    };

    const handleDialogClose = () => {
        onClose();
        HandleClear();
    };

    const handleMapLocationSelected = (latitude: number | null, longitude: number | null) => {
        if (latitude === null || longitude === null) {
            setShowValidationMessage(true);
        }
        else if (open && warehouseCode != null) {
            axios.get(VersionUrl(process.env.REACT_APP_API_URL + "/GetAllBranchesOrderedByLocation"), {
                params: {
                    latitude: latitude,
                    longitude: longitude
                }
            })
            .then(response => {
                const warehouses = response.data.warehouses;

                const filteredBranches = warehouses
                    .map((x: WarehouseSelectorProps) => ({ 
                        id: x.warehouseCode, 
                        name: x.warehouseName, 
                        fullAddress: x.warehouseFullAddress,
                        isSelected: x.warehouseCode === warehouseCode
                    }));
                setOtherBranches(filteredBranches);
            })
            .catch(error => {
                console.error(error);
            });
        }
    };
    
    useEffect(() => {
        if (open && warehouseCode != null) {
            axios.get(VersionUrl(process.env.REACT_APP_API_URL + "/GetAllBranches"), {
                params: {
                    selectedWarehouseCode: warehouseCode
                }
            })
            .then(response => {
                const warehouses = response.data.warehouses;
                setAllWarehouses(warehouses);

                const filteredBranches = warehouses
                    .map((x: WarehouseSelectorProps) => ({ 
                        id: x.warehouseCode, 
                        name: x.warehouseName, 
                        fullAddress: x.warehouseFullAddress,
                        isSelected: x.warehouseCode === warehouseCode
                    }));
                setOtherBranches(filteredBranches);
                
                if (currentWarehouse?.warehouseCode !== warehouseCode)
                    setCurrentWarehouse(warehouses.find((x: WarehouseSelectorProps) => x.warehouseCode === warehouseCode));
            })
            .catch(error => {
                console.error(error);
            });
        }
    }, [open, warehouseCode, mapType]);

    const otherBranchesColumns: GridColDef[] = [
        { field: 'name', headerName: 'Name', type: "string", flex: 0.26 },
        { field: 'fullAddress', headerName: 'Address', type: "string", flex: 0.5, renderCell: (params) => {
            var paramsArray: string[] = params.value.split("\n");
            return (
                <div key={params.value} style={{ whiteSpace: 'pre-line' }}>
                    {paramsArray.map(param => (
                        <Typography key={param}>{param}</Typography>
                    ))}
                </div>
            )            
        }},
        { field: 'fullInfo', headerName: '', type: "string", flex: 0.5, renderCell: (params) => {
            var paramsArray: string[] = params.row.fullAddress.split("\n");
            return (
                <div key={params.row.name} style={{ whiteSpace: 'pre-line' }} onClick={() => handleWarehouseSelect(params.row.id)}>
                    <Typography>{params.row.name}</Typography>
                    {paramsArray.map(param => (
                        <Typography key={param}>{param}</Typography>
                    ))}
                </div>
            );
        }},
        { field: 'options', headerName: '', flex: 0.24, align: 'center', renderCell: (params) => { 
            return params.row.isSelected ?
            (
                <Button className='whiteButton' disabled={true}>
                    Selected
                </Button>
            )
            : (
                <Button className='blueButtonOutline' onClick={() => handleWarehouseSelect(params.row.id)}>
                    Select
                </Button>
            )
        }}
    ];
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const HandleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setSearchMapQuery(searchTmpQuery);
            setMapType(MapTypeEnum.SearchText);
        }
    };

    const HandleClear = () => {
        if (searchTmpQuery !== '') {
            setSearchTmpQuery('');
            setSearchMapQuery('');
        }
        setMapType(MapTypeEnum.CurrentBranch);
    };

    const HandleMapSearch = () => {
        setSearchMapQuery(searchTmpQuery);
        setMapType(MapTypeEnum.SearchText);
    };
    
    const OnUseCurrentLocation = () => {
        setMapType(MapTypeEnum.UseMyCurrentLocation);
    };

    return (
        <Dialog open={open} onClose={handleDialogClose}  maxWidth="lg" fullWidth className='warehouseModal'>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button onClick={handleDialogClose} style={{ float: 'right' }}>
                            <CloseSharpIcon />
                        </Button>
                    </Grid>
                    <Grid item md={4} xs={12} sx={{mb:{xs:0, sm:10}, }}>
                        <h2 style={{marginTop: 0}}>YOUR BRANCH</h2>
                        <h4 style={{margin: 0}}>{warehouseName}</h4>
                        <Typography sx={{my:0}}>{currentWarehouse?.warehouseAddress}</Typography>
                        <Typography>{currentWarehouse?.warehouseCity}, {currentWarehouse?.warehouseState}, {currentWarehouse?.zipCode}</Typography>
                        <p></p>
                        <p style={{fontSize:"80%"}}><b><i>You will see inventory based on the selected branch</i></b></p>
                        
                        <div className="branchDialogMapContainer">
                            <MapComponent 
                                mapKey="BRANCH_MAP_WAREHOUSE_DIALOG" 
                                selectedWarehouseCode={warehouseCode} 
                                warehouses={allWarehouses} 
                                longitudeCenterOffset={0} 
                                onMapLocationSelected={handleMapLocationSelected}
                                disableControls={false}
                                searchQuery={searchMapQuery}
                                mapType={mapType} />
                        </div>
                    </Grid>
                    <Grid item md={8}>
                        <Grid container>       
                            <Grid item xs={12}>
                                <h2 style={{marginTop: 0}}>OTHER BRANCHES</h2>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField 
                                    size="small"
                                    variant="outlined"
                                    type="text"
                                    value={searchTmpQuery}
                                    className="mapSearchInput"
                                    onChange={(e) => setSearchTmpQuery(e.target.value)}
                                    onKeyDown={HandleSearchKeyDown}
                                    placeholder="Enter city, state, or zip code"
                                    style={{ marginRight: "10px", marginBottom:"10px" }}
                                    autoComplete="off"
                                    />
                                <Button className="blueButtonOutline mapSearchButton" sx={{mr:1, px:5}} onClick={HandleMapSearch}>Search</Button>
                                {!isMobile && 
                                    <Button className="blueButtonOutline mapSearchButton" sx={{px:5}} onClick={HandleClear}>Clear</Button>
                                }
                            </Grid>

                            <Grid item xs={12}>                                
                                <p className={`validationMessage ${showValidationMessage ? 'visible' : ''}`}>Please enter city, state, or zip code</p>
                            </Grid>

                            <Grid item xs={12}>                                
                                <div>
                                    <Button size="small" sx={{my:1}} style={{fontSize:"75%"}} variant="text" className="mapUseCurrentLocation" onClick={OnUseCurrentLocation}><MyLocationIcon fontSize="small" sx={{mr:1}}/> Use My Current Location</Button>
                                </div>
                            </Grid>

 
                            <Grid item xs={12}>
                                <DataGrid
                                    className='branchDialogTableContainer'
                                    sx={{mt:3}}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 5, page: 0 },
                                        },
                                        columns: {
                                            columnVisibilityModel: {
                                                name: !isMobile,
                                                fullAddress: !isMobile,
                                                fullInfo: isMobile
                                            }
                                        },
                                    }} 
                                    columnHeaderHeight={0}
                                    getRowHeight={() => 'auto'}
                                    pageSizeOptions={[5, 10, 25]} 
                                    rows={otherBranches} 
                                    columns={otherBranchesColumns} 
                                    disableColumnSorting={true} 
                                    disableColumnFilter={true} 
                                    disableColumnMenu={true}
                                    rowSelection={false} 
                                    autoHeight={true} />                                
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default WarehouseDialog;