import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import WarehouseHoursProps from "../../interfaces/header/WarehouseHoursProps";
import WarehouseDialog from './warehouseDialog';
import { Typography } from '@mui/material';
import pinHeaderImg from '../../img/pin-header.svg';

const WarehouseBanner: React.FC<WarehouseHoursProps> = ({ warehouseName, warehouseCode, warehouseFullAddress, 
    warehouseHoursFrom, warehouseHoursTo, message, onWarehouseSelected, openRequestedAt }) => {
    
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (openRequestedAt != null)
            setDialogOpen(true);
    }, [openRequestedAt]);
    
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    return (
        <>
            <Grid className='branchBannerContainer' 
                container
                sx={{
                    px: { lg: 3, md:3, xs: 1 },
                    py:1,
                    justifyContent: { xl: 'space-around', xs: 'flex-start' }
                }}
                spacing={0} 
                direction="row"
                alignItems="center">

                <Grid item md={12} onClick={() => handleOpenDialog()} style={{cursor: "pointer"}}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <img src={pinHeaderImg} alt="Branch Pin Thumb" height={18} width={18} />
                    <div>
                        <Typography className='branchBannerInfo'>
                            Powered By Your {warehouseName} Branch &gt;&gt;
                        </Typography>
                        <div className='branchBannerInfoWorkTime'>{message} {warehouseHoursFrom} - {warehouseHoursTo}</div>
                    </div>
                    </Stack>
                </Grid>
            </Grid>
            <WarehouseDialog 
                open={dialogOpen} 
                onClose={handleCloseDialog} 
                warehouseCode={warehouseCode} 
                warehouseName={warehouseName} 
                warehouseFullAddress={warehouseFullAddress}
                onWarehouseSelected={onWarehouseSelected} />
        </>
    );
}

export default WarehouseBanner;