import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import PageTitle from "../../utils/pageTitle";
import greenFinancingBannerImg from '../../img/program-greenfinancing-banner.png';
import greenFinancingLogoImg from '../../img/program-greenfinancing-logo.png';
import Stack from '@mui/material/Stack';
import SeoIgnore from "../../utils/seoIgnore";


const GreenFinancing = () => {
    PageTitle("Lansing Green Financing");   // Set page title

    let applyNowUrl: string = "http://www.optimusfinancing.com/lansing";
    let brochureUrl: string = process.env.REACT_APP_WEB_URL + "/images/uploaded/Lansing Green Financing - Optimus Contractor flyer 5-24.pdf";
    
    return (
        <Grid>
            <SeoIgnore></SeoIgnore>
            <div className='cp_backgroundImage' style={{backgroundImage: `url(${greenFinancingBannerImg})`}}>
                <Container maxWidth="lg">
                    <h1>
                        Lansing Green Financing
                    </h1>
                </Container>
            </div>
            <Container maxWidth="lg" sx={{my:5}}>
                <Grid  container spacing={0} direction="column">
                    <img src={greenFinancingLogoImg} width="260" height="auto" style={{marginBottom:"30px"}} alt="Lansing Green Financing" />
                    <p className='customerProgramsFont'>As a trusted partner, we are thrilled to offer Lansing Green to our customers. Close more sales and grow your business by offering consumer financing options directly to homeowners so they can make their home improvement dreams a reality. The fast, paperless application process and variety of payment plans give your potential customers spending power, and more importantly, gives you a competitive advantage.</p>
                    <Stack direction="row" spacing={2} sx={{my:4}}>
                        <a className='blueButtonOutline' href={applyNowUrl} target="_blank" rel="noreferrer">APPLY NOW  &#187;</a>
                        <a className='blueButtonOutline' href={brochureUrl} target="_blank" rel="noreferrer">BROCHURE</a>
                    </Stack>
                </Grid>
            </Container>
        </Grid>
    );
}

export default GreenFinancing;